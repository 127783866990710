.main-wrapper-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: center;

    .buttons-barcode {
        display: flex;
        justify-content: space-around;
        width: 500px;
        margin: 20px;
    }

    .content-wrapper-print {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 10.8cm;
        border: 1px solid black;
    }
}