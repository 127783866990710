.return-report-main-container {
    display: flex;
    flex-direction: column;
    padding: 20px;

    h3 {
        border-bottom: 1px solid gray;
        padding-bottom: 10px;
    }

    .return-report-date-range-selectors {
        padding: 30px;
        // width: 70%;
        display: flex;
        // justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.281);
        
        .return-report-date-picker {
            width: 250px;
            margin-right: 50px;
        }
    }

    .return-report-table-view {
        padding-top: 10px;
        display: flex;
        flex-direction: column;

        .download-table-xls-button {
            width: 150px;
            background-color: rgb(0, 0, 0);
            border: none;
            margin-bottom: 10px;
            box-shadow: 2px 2px 2px gray;
            font-size: 1rem;
            color: white;
            align-self: flex-end;
            border-radius: 5px;
        }
    }
}

.debit-note-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        width: 98%;
        // height: 1748px;
        // border: 0.5px solid black;

        .details-section {
            width: 98%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            font-size: 0.8rem;
            padding: 10px;
            border-bottom: 1px solid black;
            border-top: 1px solid black;

            .right-address-section {
                display: flex;
                flex-direction: column;
            }
        }
    }