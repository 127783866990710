.expense-tracker-main-container {
    padding: 20px;

    .expense-log-form {
        padding: 10px;
        border-bottom: 5px solid rgba(0, 0, 0, 0.274);
        margin-bottom: 30px;
    }

    .expense-view-all-button {
        padding-top: 20px;
    }
}

.download-table-xls-button {
    background-color: black;
    color: white;
    margin: 10px 0px;
}



@media screen and (max-width: 700px) {
    .expense-report-date-range-selectors {
        display: flex;
        flex-direction: column;
    }
}