.warehouse-returned-products-report-main-container {
    display: flex;
    flex-direction: column;
    padding: 20px;

    h3 {
        border-bottom: 1px solid gray;
        padding-bottom: 10px;
    }

    .warehouse-returned-products-report-date-range-selectors {
        padding: 30px;
        // width: 70%;
        display: flex;
        // justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.281);
        
        .warehouse-returned-products-report-date-picker {
            width: 250px;
            margin-right: 50px;
        }
    }

    .warehouse-returned-products-report-table-view {
        padding-top: 10px;
        display: flex;
        flex-direction: column;

        .download-table-xls-button {
            width: 150px;
            background-color: rgb(0, 0, 0);
            border: none;
            margin-bottom: 10px;
            box-shadow: 2px 2px 2px gray;
            font-size: 1rem;
            color: white;
            align-self: flex-end;
            border-radius: 5px;
        }
    }
}