.barcode-gen-main-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 189px;
    max-height: 94px;
    // border: 1px solid black;
    padding: 3.7px;
    margin-top: 3.7px;
    margin-bottom: 3.7px;
    align-items: center;

    .heading-barcode {
        font-size: 0.4rem;
        font-weight: bold;
    }

    .item-name{
        font-size: 0.4rem;
        font-weight: bold;
        text-transform: capitalize;
    }

    .price-line {
        display: flex;
        width: 100%;
        justify-content: space-around;
        font-size: 0.4rem;
        font-weight: bold;
    }

    .barcode-svg {
        width: 185.19px;
        max-height: 71.81px;
    }
}