.collection-admin-main-container {
    padding: 20px;
    min-height: 100vh;

    .total-collection {
        min-height: 100px;
        border: 10px solid lightblue;
        margin: 20px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .collection-tracker-report {
        .table-top-conatiner {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}