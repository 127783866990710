.indent-report-main-container {
    padding: 30px;
    display: flex;
    flex-direction: column;

    .download-table-xls-button {
            width: 150px;
            background-color: rgb(0, 0, 0);
            border: none;
            margin-bottom: 10px;
            box-shadow: 2px 2px 2px gray;
            font-size: 1rem;
            color: white;
            align-self: flex-end;
            border-radius: 5px;
    }
}