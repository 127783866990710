.manage-products-main-container {
    display: flex;
    flex-direction: column;

    .add-product-block {
        padding: 30px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.404);

        Button {
            margin-top: 20px;
            box-shadow: 10px 10px 5px gray;
        }
    }

    .search-item {
        padding: 20px;
        width: 50%;
    }

    .product-view {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
    }
}