.upload-view-bills-main-container {
    display: flex;
    flex-direction: column;

    .upload-bills-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-bottom: 1px solid gray;

        .add-file-wrapper {
            display: flex;
            flex-direction: column;
            width: 500px;

            .add-article-image-upload-field {
                margin-top: 10px;
            }

            .upload-image-progress {
                margin-top: 10px;
            }
            
            .upload-image-button {
                margin-top: 20px;
                width: 300px;
            }
        }

        .add-extra-details-wrapper {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            width: 500px;
        }

    }

    .view-bills-container {
        display: flex;
        padding: 20px;

        .view-bills-main-container {
            display: flex;
            flex-direction: column;
            padding: 20px;

            h3 {
                border-bottom: 1px solid gray;
                padding-bottom: 10px;
            }

            .view-bills-date-range-selectors {
                padding: 30px;
                // width: 70%;
                display: flex;
                // justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.281);
                
                .view-bills-date-picker {
                    width: 250px;
                    margin-right: 50px;
                }
            }

            .view-bills-table-view {
                padding-top: 10px;
                display: flex;
                flex-direction: column;
                min-width: 85vw;

                .download-table-xls-button {
                    width: 150px;
                    background-color: rgb(0, 0, 0);
                    border: none;
                    margin-bottom: 10px;
                    box-shadow: 2px 2px 2px gray;
                    font-size: 1rem;
                    color: white;
                    align-self: flex-end;
                    border-radius: 5px;
                }
            }
        }
    }
}